<template>
  <el-result subTitle=" T_T 走丢了,您访问的页面不存在 T_T" title="404">
    <template slot="icon">
      <img alt="走丢了" height="400px" src="../assets/404.png" width="400px">
    </template>
    <template slot="extra">
      <el-button size="medium" type="[primary]" @click="$router.back()">返回</el-button>
    </template>
  </el-result>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>

</style>
